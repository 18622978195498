import React from 'react';
import { Link } from 'gatsby';
import classnames from 'classnames';

type CTAProps = {
  label: string;
  linkTo: string;
  small?: boolean;
  green?: boolean;
};

/* eslint-disable-next-line  object-curly-newline */
const CTA = ({ label, linkTo, small, green }: CTAProps) => (
  <div
    className={classnames(
      'CTA',
      { 'CTA--small': small },
      { 'CTA--green': green },
    )}
  >
    <Link className="CTA__button" type="button" to={linkTo}>
      {label}
    </Link>
  </div>
);

CTA.defaultProps = {
  small: false,
  green: false,
};

export default CTA;
