import React from 'react';
import CTA from '@/components/common/CTA';
import Logo from '../../../../static/images/logo-get-your-team.svg';

const Header = () => (
  <div className="header">
    <div className="header__inner">
      <div className="header__wrapper">
        <div className="logo">
          <h1 className="logo__text">
            <a href="/" title="get your team">
              <Logo />
            </a>
          </h1>
        </div>
        <nav className="header-menu">
          <ul>
            <li className="header-menu__item">
              <a href="/#how" title="how">
                how
              </a>
            </li>
            <li className="header-menu__item">
              <a href="/#what" title="what">
                what
              </a>
            </li>
            <li className="header-menu__item">
              <a href="/#who" title="who">
                who
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <div className="header__cta">
        {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
        <CTA label="Get started" linkTo="/contact" small green />
      </div>
    </div>
  </div>
);

export default Header;
