import React from 'react';
import { Link } from 'gatsby';
import CookieConsent, { Cookies } from 'react-cookie-consent';

// const env =
//   process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';
// const isDev = env === 'development';

const CookieBanner = () => {
  const handleOnAccept = () => {
    Cookies.set('t3-google-analytics', true, { expires: 150 });
    Cookies.set('t3-facebook-pixel', true, { expires: 150 });
    Cookies.set('t3-hotjar', true, { expires: 150 });
    // set extra cookies here
  };

  return (
    <CookieConsent
      location="bottom"
      buttonText="ACCEPT"
      buttonClasses="cookie-banner__btn"
      containerClasses="cookie-banner__container"
      contentClasses="cookie-banner__content"
      disableStyles
      disableButtonStyles
      cookieName="t3-google-analytics"
      onAccept={handleOnAccept}
    >
      {'This website uses cookies to improve your experience.'}{' '}
      <Link to="/privacy-policy">More information</Link>
    </CookieConsent>
  );
};

export default CookieBanner;
