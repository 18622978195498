import React from 'react';
import CookieBanner from '@/components/common/CookieBanner';

import '@/styles/main.scss';

type LayoutProps = {
  children: React.ReactNode;
  className?: string;
};

const Layout = ({ children, className }: LayoutProps) => (
  <div className={className}>
    {children}
    <CookieBanner />
  </div>
);

Layout.defaultProps = {
  className: '',
};

export default Layout;
